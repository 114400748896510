import SvgIcon from "@mui/material/SvgIcon";
import { ReactSVG } from "react-svg";
import arrowBack from "../assets/img/arrow-back.svg";
import arrowIcon from "../assets/img/icons/arrow.png";
import dateIcon from "../assets/img/icons/date.png";
import inspectionIcon from "../assets/img/icons/inspectionNo.png";
import requestIcon from "../assets/img/icons/requestNo.png";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

export const Months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const STAGE_API_URL = "https://stage.detect-inspections.com";
export const PROD_API_URL = "https://service.detect-inspections.com";
export const TEST_API_URL = "http://34.71.230.126";

export const chartData1 = {
  datasets: [
    {
      data: [1, 2, 3],
      backgroundColor: [
        "rgba(221, 44, 0, 0.8)",
        "rgba(249, 168, 37, 0.8)",
        "rgba(102, 187, 106, 0.8)",
      ],
      borderColor: [
        "rgba(221, 44, 0, 1)",
        "rgba(249, 168, 37, 1)",
        "rgba(102, 187, 106, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

export const StatusConstants = {
  1: "PENDING",
  2: "PHOTOS UPLOADED",
  3: "ANNOTATED",
  4: "REVIEWED",
  5: "REJECTED",
  6: "APPROVED",
};

export const getStatusStyles = (val) => {
  switch (val) {
    case 1:
      return {
        backgroundColor: "rgba(204, 204, 204, 0.8)",
        color: "#000000",
      };
    case 2:
      return {
        backgroundColor: "rgba(159, 197, 248, 0.5)",
        color: "#0a4273",
      };
    case 3:
      return {
        backgroundColor: "#fee2d3",
        color: "#ff8039",
      };
    case 4:
      return {
        backgroundColor: "rgba(188, 119, 66, 0.45)",
        color: "#633900",
      };
    case 5:
      return {
        backgroundColor: "#ffd7d7",
        color: "#dd2c00",
      };
    case 6:
      return {
        backgroundColor: "#ddffcd",
        color: "#007f00",
      };
    default:
      return {}; // Default styles
  }
};

export const UploadStatusConstants = {
  0: "FAILED",
  1: "SUCCESSFUL",
};

export const getUploadStatusStyles = (val) => {
  switch (val) {
    case 0:
      return {
        backgroundColor: "#ffd7d7",
        color: "#dd2c00",
      };
    case 1:
      return {
        backgroundColor: "#ddffcd",
        color: "#007f00",
      };
    default:
      return {};
  }
};

export const UPLOAD_BATCH_SIZE = 10;

export const ThreeDotsIcon = (props) => {
  return (
    <SvgIcon {...props} width="50" height="20" viewBox="0 0 50 20">
      <circle cx="10" cy="10" r="4" />
      <circle cx="25" cy="10" r="4" />
      <circle cx="40" cy="10" r="4" />
    </SvgIcon>
  );
};

export const PrevArrow = ({ onClick, isDisabled }) => {
  return (
    <div
      className="back-carousal"
      onClick={onClick}
      style={{ display: isDisabled ? "none" : "" }}
    >
      <ReactSVG src={arrowBack} />
    </div>
  );
};

export const NextArrow = ({ onClick, isDisabled }) => {
  return (
    <div
      className="front-carousal"
      onClick={onClick}
      style={{ display: isDisabled ? "none" : "" }}
    >
      <ReactSVG src={arrowBack} />
    </div>
  );
};

export const statusOptions = [
  {
    label: "PENDING",
    backgroundColor: "rgba(204, 204, 204, 0.8)",
    color: "#000000",
    value: 1,
  },
  {
    label: "PHOTOS UPLOADED",
    backgroundColor: "rgba(159, 197, 248, 0.5)",
    color: "#0a4273",
    value: 2,
  },
  {
    label: "ANNOTATED",
    backgroundColor: "#fee2d3",
    color: "#ff8039",
    value: 3,
  },
  {
    label: "REVIEWED",
    backgroundColor: "rgba(188, 119, 66, 0.45)",
    color: "#633900",
    value: 4,
  },
  {
    label: "REJECTED",
    backgroundColor: "#ffd7d7",
    color: "#dd2c00",
    value: 5,
  },
  {
    label: "APPROVED",
    backgroundColor: "#ddffcd",
    color: "#007f00",
    value: 6,
  },
];

export const defectOptions = [
  { label: "High", icon: arrowIcon, rotation: 180, value: "high" },
  { label: "Medium", icon: arrowIcon, rotation: 270, value: "medium" },
  { label: "Low", icon: arrowIcon, rotation: 360, value: "low" },
];

export const inspectionsSortOptions = [
  { label: "Date", icon: dateIcon, value: "date" },
  {
    label: "Line Num",
    icon: inspectionIcon,
    value: "inspections",
  },
  { label: "Request", icon: requestIcon, value: "requests" },
];

export const severityOptions = [
  { id: 1, severity: "Good", color: "#848484" },
  { id: 2, severity: "Low", color: "#66bb6a" },
  { id: 3, severity: "Medium", color: "#f9a825" },
  { id: 4, severity: "High", color: "#dd2c00" },
];

export const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#ffffff"), // Text color
  backgroundColor: "#ffffff", // Background color
  borderRadius: "16px", // Rounded corners
  border: "2px solid #333", // Dark grey border
  padding: "4px 10px",
  fontSize: "11.5px",
  "&:hover": {
    backgroundColor: "#e0e0e0", // Slightly darker shade on hover
  },
  width: "141px",
}));

export const annotationSeverityOptions = [
  {
    id: 1,
    severity: "Good",
    color: "rgb(202, 202, 202)",
    fill: "rgb(202, 202, 202, 0.1)",
  },
  {
    id: 2,
    severity: "Low",
    color: "rgb(102, 187, 106)",
    fill: "rgb(102, 187, 106, 0.1)",
  },
  {
    id: 3,
    severity: "Medium",
    color: "rgb(249, 168, 37)",
    fill: "rgb(249, 168, 37, 0.1)",
  },
  {
    id: 4,
    severity: "High",
    color: "rgb(221, 44, 0)",
    fill: "rgb(221, 44, 0, 0.1)",
  },
];

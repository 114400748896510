import React, { Component } from "react";
import Map, {
  FullscreenControl,
  NavigationControl,
  ScaleControl,
  Source,
  Layer,
} from "react-map-gl";
import { LngLatBounds } from "mapbox-gl";

import MapTypeControl from "./MapTypeControl";
import "mapbox-gl/dist/mapbox-gl.css";
import MarkerObject from "./MarkerObject";
import InspectionObject from "./InspectionObject";
import HeatMapSource from "../MainContent/HeatMapSource";
import GradientPolylineSource from "../MainContent/GradientPolylineSource";

class MapContainer extends Component {
  constructor(props) {
    super(props);

    this.map = React.createRef();

    let center = props.center ? props.center : [0, 0];
    if (props.structures && props.structures.length) {
      center = props.structures[0].coordinates;
    } else if (
      props.inspections &&
      Object.values(props.inspections).length &&
      Object.values(props.inspections)[0].structures.length
    ) {
      center = [
        Object.values(props.inspections)[0].structures[0].lng,
        Object.values(props.inspections)[0].structures[0].lat,
      ];
    }

    this.state = {
      center: center,
      mapStyle: "2d",
      zoomLevel: 10,
      activeStructureIdentifier: props.activeStructureIdentifier,
      isCursorPointer: false,
    };

    this.mapStyles = {
      "2d": "mapbox://styles/mapbox/light-v11",
      "3d": "mapbox://styles/mapbox/satellite-streets-v12",
    };

    this.onZoomEnd = this.onZoomEnd.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onStyleData = this.onStyleData.bind(this);
    this.onMapTypeChanged = this.onMapTypeChanged.bind(this);
    this.onMarkerClicked = this.onMarkerClicked.bind(this);
    this.onMapLoaded = this.onMapLoaded.bind(this);
  }

  onMapLoaded() {
    if (this.props.structures) {
      let coords = null;
      for (const structure of this.props.structures) {
        if (!coords) {
          coords = new LngLatBounds(
            [
              parseFloat(structure.coordinates[1]),
              parseFloat(structure.coordinates[0]),
            ],
            [
              parseFloat(structure.coordinates[1]),
              parseFloat(structure.coordinates[0]),
            ]
          );
        } else {
          coords.extend([
            parseFloat(structure.coordinates[1]),
            parseFloat(structure.coordinates[0]),
          ]);
        }
      }

      if (coords) {
        this.map.current.fitBounds(coords, { animate: true, padding: 20 });
      }
    }
  }

  onZoomEnd(e) {
    /*this.setState({
            zoomLevel: e.viewState.zoom
        });*/
  }

  onClick(e) {
    if (e.features && e.features.length) {
      for (const feature of e.features) {
        if (
          feature.layer &&
          feature.layer.id.indexOf("grad-poly-layer-phantom") !== -1
        ) {
          const inspectionId = feature.layer.id.replace(
            "grad-poly-layer-phantom-",
            ""
          );

          window.location.href = "/inspections/" + inspectionId;
          return;
        }
      }
    }

    if (this.props.onStructureSelected) {
      this.props.onStructureSelected();
    }
  }

  onMouseMove(e) {
    let isCursorPointer = false;

    if (e.features && e.features.length) {
      for (const feature of e.features) {
        if (
          feature.layer &&
          feature.layer.id.indexOf("grad-poly-layer-phantom") !== -1
        ) {
          const inspectionId = feature.layer.id.replace(
            "grad-poly-layer-phantom-",
            ""
          );

          isCursorPointer = true;
        }
      }
    }

    // if (this.state.isCursorPointer !== isCursorPointer) {
    //   this.setState({
    //     isCursorPointer: isCursorPointer,
    //   });
    // }
  }

  onMarkerClicked(structure) {
    let newActiveStructureIdentifier = structure.identifier;
    if (this.state.activeStructureIdentifier === structure.identifier) {
      newActiveStructureIdentifier = null;
    }

    this.props.onStructureSelected(newActiveStructureIdentifier);

    this.setState({
      activeStructureIdentifier: newActiveStructureIdentifier,
    });
  }

  flyToMarker(structure) {
    let closerZoom = this.state.zoomLevel;
    if (closerZoom < 15) {
      closerZoom = 15;
    }

    this.map.current.flyTo({
      center: [structure.coordinates[1], structure.coordinates[0]],
      zoom: closerZoom,
    });
  }

  onStyleData(e) {
    let newPitch = 0;
    if (this.state.mapStyle === "3d") {
      newPitch = 60;
    }
    e.target.setPitch(newPitch, { duration: 2000 });
    e.target.getContainer().classList.remove("type-2d");
    e.target.getContainer().classList.remove("type-3d");
    e.target.getContainer().classList.add("type-" + this.state.mapStyle);
  }

  onMapTypeChanged(newType) {
    this.setState({
      mapStyle: newType,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.activeStructureIdentifier !==
      this.props.activeStructureIdentifier
    ) {
      this.setState({
        activeStructureIdentifier: this.props.activeStructureIdentifier,
      });
    } else if (
      prevState.activeStructureIdentifier !==
      this.state.activeStructureIdentifier
    ) {
      for (const object of this.props.structures) {
        if (object.title === this.state.activeStructureIdentifier) {
          this.flyToMarker(object);
        }
      }
    } else if (prevState.inspections !== this.props.inspections) {
      if (
        this.props.inspections &&
        this.props.inspections.length &&
        this.props.inspections[0].structure_data.length
      ) {
        let coords = null;
        for (const inspection of this.props.inspections) {
          for (const structureData of inspection.structure_data) {
            if (!coords) {
              coords = new LngLatBounds(
                [parseFloat(structureData[1]), parseFloat(structureData[0])],
                [parseFloat(structureData[1]), parseFloat(structureData[0])]
              );
            } else {
              coords.extend([
                parseFloat(structureData[1]),
                parseFloat(structureData[0]),
              ]);
            }
          }
        }

        if (coords) {
          this.map.current.fitBounds(coords, { animate: false, padding: 20 });
        }
      }
    }
  }

  render() {
    const skyLayer = {
      id: "sky",
      type: "sky",
      paint: {
        "sky-type": "atmosphere",
        "sky-atmosphere-sun": [0.0, 0.0],
        "sky-atmosphere-sun-intensity": 15,
      },
    };

    const interactiveLayerIds = [];
    if (this.props.inspections) {
      for (const inspection of Object.values(this.props.inspections)) {
        let layerId = "grad-poly-layer-phantom-" + inspection.request_id;
        interactiveLayerIds.push(layerId);
      }
    }

    let mapContainerClass =
      "map-container" + (this.props.mobileMapVisible ? " map-visible" : "");
    if (this.state.isCursorPointer) {
      mapContainerClass += " cursor-pointer";
    }

    return (
      <div className={mapContainerClass}>
        <Map
          ref={this.map}
          mapStyle={this.mapStyles[this.state.mapStyle]}
          style={{
            height: "100%",
            width: "100%",
          }}
          initialViewState={{
            longitude: this.state.center[1],
            latitude: this.state.center[0],
            pitch: 0,
            zoom: this.state.zoomLevel,
          }}
          terrain={
            this.state.mapStyle === "3d"
              ? {
                  source: "mapbox-raster-dem",
                  exaggeration: 1,
                }
              : {}
          }
          onZoomEnd={this.onZoomEnd}
          onClick={this.onClick}
          onLoad={this.onMapLoaded}
          onMouseMove={this.onMouseMove}
          onStyleData={this.onStyleData}
          interactiveLayerIds={interactiveLayerIds}
        >
          <ScaleControl unit="metric" />
          <NavigationControl visualizePitch={true} />
          <FullscreenControl />
          <MapTypeControl
            position="top-left"
            onMapTypeChanged={this.onMapTypeChanged}
          />

          <Source
            id="mapbox-raster-dem"
            type="raster-dem"
            url="mapbox://mapbox.mapbox-terrain-dem-v1"
            tileSize="512"
            maxzoom="14"
          >
            <Layer {...skyLayer} />
          </Source>

          {this.props.inspections && this.props.inspectionsGradient && (
            <HeatMapSource inspections={this.props.inspections} />
          )}

          {this.props.inspections &&
            this.props.inspectionsGradient &&
            Object.values(this.props.inspections).map((inspection) => (
              <GradientPolylineSource
                key={inspection.request_id.toString()}
                inspection={inspection}
                //onPolylineClicked={this.onMarkerClicked}
              />
            ))}

          {this.props.structures &&
            this.props.structures.map((structure) => (
              <MarkerObject
                key={structure.identifier}
                structure={structure}
                zoomLevel={this.state.zoomLevel}
                showPopup={
                  this.state.activeStructureIdentifier === structure.identifier
                }
                onMarkerClicked={this.onMarkerClicked}
              />
            ))}

          {this.props.inspections &&
            Object.values(this.props.inspections).map((inspection) => (
              <InspectionObject
                key={"inspection-" + inspection.request_id.toString()}
                inspection={inspection}
              />
            ))}
        </Map>
      </div>
    );
  }
}

export default MapContainer;

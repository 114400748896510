import React, { useEffect, useRef, useState, useCallback } from "react";
import Map, { Marker } from "react-map-gl";
import { LngLatBounds } from "mapbox-gl";
import currentMarker from "../../assets/img/icons/currentMarker.png";
import drone from "../../assets/img/icons/drone.png";
import selected from "../../assets/img/icons/clicked.png";
import square from "../../assets/img/icons/square.png";
import satellite from "../../assets/img/icons/satellite.png";
import Rearrange from "../../assets/img/icons/rearrange.png";
import Tooltip from "@mui/material/Tooltip";
import Popup from "../../helpers/Popup";
import { showNotificationToast } from "../../helpers/Messages";
import Loader from "../../helpers/Loader";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

const MapComponent = ({
  selectedItemId,
  checkedItems,
  selectedCount,
  setSelectedCount,
  setReassignImages,
  checkedFilenames,
  mapToolHeight,
}) => {
  const mapRef = useRef();
  const [selectedStructure, setSelectedStructure] = useState(null);
  const [mapData, setMapData] = useState(null);
  const [viewport, setViewport] = useState({
    latitude: 0,
    longitude: 0,
    zoom: 14,
  });
  const [loading, setLoading] = useState(true);
  const [isSatelliteView, setIsSatelliteView] = useState(false);
  const [reassignPopup, setReassignPopup] = useState(false);

  const handleStateReset = () => {
    setViewport({
      latitude: 0,
      longitude: 0,
      zoom: 14,
    });
    setReassignPopup(false);
  };

  useEffect(() => {
    handleStateReset();
  }, [selectedItemId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await window.apiHelper.getMapData(selectedItemId);
        setMapData(data);
        setViewport({
          latitude: data?.current_structure.lat,
          longitude: data?.current_structure.lon,
          zoom: 14,
        });
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    };
    fetchData();
  }, [selectedItemId]);

  const handleMapLoad = () => {
    if (!loading && mapData && mapRef.current) {
      const bounds = new LngLatBounds();
      mapData.nearby_structures.forEach((structure) => {
        bounds.extend([structure.lon, structure.lat]);
      });
      bounds.extend([
        mapData.current_structure.lon,
        mapData.current_structure.lat,
      ]);
      bounds.extend([
        mapData.image_metadata.pass.lon,
        mapData.image_metadata.pass.lat,
      ]);

      mapRef.current.fitBounds(bounds, { animate: true, padding: 65 });
    }
  };

  const handleMarkerClick = (structure) => {
    setSelectedStructure((prevSelected) =>
      prevSelected === structure ? null : structure
    );
  };

  const handleReassignPopup = () => {
    setReassignPopup(true);
  };

  const handleReassignPopupAccept = () => {
    const idsArray = Object.keys(checkedItems);
    const data = {
      image_ids: idsArray,
      destination_structure_id: selectedStructure.id,
    };
    handleReorganizeImages(data);
  };

  const handleReorganizeImages = async (data) => {
    try {
      const response = await window.apiHelper.postRestructingImages(data);
      if (response) {
        setReassignImages(true);
        setReassignPopup(false);
        showNotificationToast(
          ` ${selectedCount} ${
            selectedCount > 1 ? "images" : "image"
          } reassigned to ${selectedStructure.identifier} `
        );
        setSelectedCount(0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReassignPopupReject = () => {
    setReassignPopup(false);
  };

  if (loading) {
    return (
      <div style={{ position: "relative", height: "400px", width: "100%" }}>
        <Loader />
      </div>
    );
  }

  return (
    <>
      {reassignPopup && (
        <Popup
          text={
            <>
              <p>
                Are you sure you want to reassign these {selectedCount} images
                from {mapData?.current_structure.identifier} to{" "}
                {selectedStructure.identifier} ?
              </p>
              <p>Selected images:</p>
              <SimpleBar
                className="custom-simplebar"
                style={{
                  maxHeight: 125,
                  marginRight: 5,
                }}
              >
                <ul className="no-dots-list">
                  {checkedFilenames.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </SimpleBar>
            </>
          }
          onAccept={handleReassignPopupAccept}
          onReject={handleReassignPopupReject}
          type="option"
        />
      )}
      <div
        style={{
          width: "100%",
          height: mapToolHeight - 21,
          position: "relative",
        }}
      >
        <div className="satellite-toggle-button">
          <button
            onClick={() => setIsSatelliteView(false)}
            style={{
              borderRadius: "5px 0 0 5px",
              border: "none",
              backgroundColor: isSatelliteView ? "white" : "#e0e0e0",
            }}
          >
            <img
              src={square}
              alt="custom"
              style={{ width: "25px", padding: "2px" }}
            />
          </button>
          <button
            onClick={() => setIsSatelliteView(true)}
            style={{
              borderRadius: "0 5px 5px 0",
              border: "none",
              backgroundColor: isSatelliteView ? "#e0e0e0" : "white",
            }}
          >
            <img src={satellite} alt="satellite" style={{ width: "25px" }} />
          </button>
        </div>

        <div className="reassign-button" onClick={handleReassignPopup}>
          <Tooltip
            title={
              selectedStructure
                ? selectedCount > 0
                  ? "Reassign"
                  : "No Image Selected"
                : "Select a structure"
            }
            arrow
            placement="top"
          >
            <button
              disabled={
                selectedStructure ? (selectedCount > 0 ? false : true) : true
              }
              style={{
                borderRadius: "5px",
                border: "none",
                backgroundColor: "white",
              }}
            >
              <img
                src={Rearrange}
                alt="satellite"
                style={{ width: "25px", padding: "2px" }}
              />
            </button>
          </Tooltip>
        </div>

        {selectedStructure && (
          <div className="selected-structure-heading">
            Selected Structure: {selectedStructure.identifier}
          </div>
        )}
        <Map
          ref={mapRef}
          initialViewState={viewport}
          onMove={(evt) => setViewport(evt.viewState)}
          onLoad={handleMapLoad}
          style={{
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            border: selectedStructure ? "5px solid #27c59b" : "none",
            borderRadius: "8px",
          }}
          mapStyle={
            isSatelliteView
              ? "mapbox://styles/mapbox/satellite-v9"
              : "mapbox://styles/mapbox/light-v11"
          }
        >
          <Marker
            latitude={mapData?.image_metadata.pass.lat}
            longitude={mapData?.image_metadata.pass.lon}
            anchor="center"
          >
            <div
              style={{
                transform: `rotate(${mapData?.image_metadata.pass.heading_degree}deg)`,
                pointerEvents: "none",
              }}
            >
              <img src={drone} alt="drone marker" />
            </div>
          </Marker>
          {mapData?.nearby_structures.map((structure) => (
            <Marker
              key={structure.identifier}
              latitude={structure.lat}
              longitude={structure.lon}
              anchor="center"
              onClick={() => handleMarkerClick(structure)}
            >
              {JSON.stringify(selectedStructure) ===
              JSON.stringify(structure) ? (
                <div style={{ position: "relative" }}>
                  <img
                    src={selected}
                    alt="selected marker"
                    style={{ width: "21px", height: "22px" }}
                  />
                  <span className="selected-structure-subheading">
                    {structure.identifier}
                  </span>
                </div>
              ) : (
                <Tooltip
                  title={structure.identifier}
                  followCursor
                  placement="top"
                >
                  <div className="dot-marker" />
                </Tooltip>
              )}
            </Marker>
          ))}
          <Marker
            latitude={mapData?.current_structure.lat}
            longitude={mapData?.current_structure.lon}
            anchor="center"
          >
            <Tooltip
              title={mapData?.current_structure.identifier}
              followCursor
              placement="top"
            >
              <img src={currentMarker} alt="current marker" />
            </Tooltip>
          </Marker>
        </Map>
      </div>
    </>
  );
};

export default MapComponent;

import React from "react";
import { useNavigate } from "react-router-dom";

import profilePic from "../../assets/img/logo.svg";

const BarProfile = (props) => {
  const navigate = useNavigate();

  return (
    <div id="profile-pic" onClick={() => navigate("/")}>
      <img src={profilePic} className="avatar" alt="avatar" />
    </div>
  );
};

export default BarProfile;

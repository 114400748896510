import React, { Component } from "react";
import PropTypes from "prop-types";

import { Link, Outlet } from "react-router-dom";

import { ReactSVG } from "react-svg";

import logoFull from "./../../assets/img/detect-logo-with-text.svg";
import logo from "./../../assets/img/logo.svg";

class AuthPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div id="auth-cover">
        <div id="auth-form">
          <ReactSVG src={logo} className="svg-wrapper" />

          <Outlet />

          <p id="auth-copyright">
            &copy; Detect Technologies Corporation {new Date().getFullYear()}
          </p>
        </div>
        <div id="auth-info">
          <ReactSVG src={logoFull} className="svg" />
        </div>
      </div>
    );
  }
}

export default AuthPage;
